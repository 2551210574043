import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    /*{
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'administrative-page',
                title: 'Administrativo',
                translate: 'NAV.ADMINISTRATIVE.TITLE',
                type: 'collapsable',
                icon: 'settings',
                children: [
                    {
                        id: 'factory-page',
                        title: 'Fábricas',
                        type: 'item',
                        icon: 'business',
                        url: '/auth/admin/factory',
                    },
                    {
                        id: 'checklistItem-page',
                        title: 'Itens do CheckList',
                        type: 'item',
                        icon: 'assignment',
                        url: '/auth/admin/checklistItem',
                    },
                    {
                        id: 'driver-page',
                        title: 'Motoristas',
                        type: 'item',
                        icon: 'assignment_ind',
                        url: '/auth/admin/driver',
                    },
                    {
                        id: 'access-profile',
                        title: 'Perfis de Acesso',
                        type: 'item',
                        icon: 'lock',
                        url: '/auth/admin/access-profile',
                    },
                    {
                        id: 'plaque-page',
                        title: 'Placas de Veículos e Tanques',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/auth/admin/plaque',
                    },
                    {
                        id: 'vehicle-type-page',
                        title: 'Tipo de Veículos',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/auth/admin/vehicle-type',
                    },
                    {
                        id: 'shipping-company-page',
                        title: 'Transportadora',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/auth/admin/shipping-company',
                    },
                    {
                        id: 'user-page',
                        title: 'Usuários',
                        type: 'item',
                        icon: 'person',
                        url: '/auth/admin/user',
                    },
                ],
            },
            {
                id: 'consult-page',
                title: 'Carregamento',
                translate: 'NAV.CONSULT.TITLE',
                type: 'collapsable',
                icon: 'opacity',
                children: [
                    {
                        id: 'vehicle-line-page',
                        title: 'Pesagem Inicial',
                        type: 'item',
                        icon: 'hourglass_empty',
                        url: '/auth/charge/initial-weighing',
                    },
                    {
                        id: 'vehicle-line-page',
                        title: 'Pesagem Final',
                        type: 'item',
                        icon: 'hourglass_full',
                        url: '/auth/charge/final-weighing',
                    },
                ],
            },
            {
                id: 'consult-page',
                title: 'Consultar',
                translate: 'NAV.CONSULT.TITLE',
                type: 'collapsable',
                icon: 'find_in_page',
                children: [
                    {
                        id: 'message-page',
                        title: 'Mensagens',
                        type: 'item',
                        icon: 'sms',
                        url: '/auth/consult/message',
                    },
                    {
                        id: 'vehicle-line-page',
                        title: 'Situação Veículos',
                        type: 'item',
                        icon: 'commute',
                        url: '/auth/consult/vehicle-line',
                    },
                ],
            },
            {
                id: 'dashboard-page',
                title: 'Dashboard',
                translate: 'NAV.REQUEST.TITLE',
                type: 'collapsable',
                icon: 'bubble_chart',
                children: [
                    {
                        id: 'trip-details-page',
                        title: 'Detalhamento da Viagem',
                        type: 'item',
                        icon: 'timeline',
                        url: '/auth/dashboard/trip-details',
                    },
                    {
                        id: 'management-page',
                        title: 'Gerencial',
                        type: 'item',
                        icon: 'device_hub',
                        url: '/auth/dashboard/management',
                    },
                    {
                        id: 'monitoring-panel-page',
                        title: 'Painel de Monitoria',
                        type: 'item',
                        icon: 'find_replace',
                        url: '/auth/dashboard/monitoring-panel',
                    },
                ],
            },
            {
                id: 'logistic-page',
                title: 'Logística',
                translate: 'NAV.LOGISTIC.TITLE',
                type: 'collapsable',
                icon: 'local_shipping',
                children: [
                    {
                        id: 'vehicle-load-page',
                        title: 'Cargas e veículos',
                        type: 'item',
                        icon: 'call_split',
                        url: '/auth/logistic/vehicle-load',
                    },
                    {
                        id: 'message-page',
                        title: 'Mensagens',
                        type: 'item',
                        icon: 'sms',
                        url: '/auth/logistic/message',
                    },
                    {
                        id: 'solicitation-forecast-logistic-page',
                        title: 'Solicitação Forecast',
                        type: 'item',
                        icon: 'gavel',
                        url: '/auth/logistic/solicitation-forecast',
                    },
                ],
            },
            {
                id: 'operation-page',
                title: 'Operação',
                translate: 'NAV.OPERATION.TITLE',
                type: 'collapsable',
                icon: 'build',
                children: [
                    {
                        id: 'forecast-page',
                        title: 'Forecast',
                        type: 'item',
                        icon: 'settings',
                        url: '/auth/operation/forecast',
                    },
                    {
                        id: 'production-sequencing-operation-page',
                        title: 'Sequenciamento de Produção',
                        type: 'item',
                        icon: 'format_list_numbered',
                        url: '/auth/operation/production-sequencing',
                    },
                    {
                        id: 'solicitation-forecast-operation-page',
                        title: 'Solicitação Forecast',
                        type: 'item',
                        icon: 'gavel',
                        url: '/auth/operation/solicitation-forecast',
                    },
                ],
            },
            {
                id: 'request-page',
                title: 'Pedidos',
                translate: 'NAV.REQUEST.TITLE',
                type: 'collapsable',
                icon: 'account_balance_wallet',
                children: [
                    {
                        id: 'administer-page',
                        title: 'Administrar',
                        type: 'item',
                        icon: 'assignment',
                        url: '/auth/request/administer',
                    },
                    {
                        id: 'reverse-balance-page',
                        title: 'Estornar Saldo',
                        type: 'item',
                        icon: 'call_missed',
                        url: '/auth/request/reverse-balance',
                    },
                    {
                        id: 'import-page',
                        title: 'Importar',
                        type: 'item',
                        icon: 'input',
                        url: '/auth/request/import',
                    },
                ],
            },
            {
                id: 'concierge-page',
                title: 'Portaria',
                translate: 'NAV.REQUEST.TITLE',
                type: 'collapsable',
                icon: 'commute',
                children: [
                    {
                        id: 'vehicle-exit-page',
                        title: 'Saída de Veículos',
                        type: 'item',
                        icon: 'exit_to_app',
                        url: '/auth/concierge/vehicle-exit',
                    }
                ],
            },
            {
                id: 'reports-page',
                title: 'Relatórios',
                translate: 'NAV.REQUEST.TITLE',
                type: 'collapsable',
                icon: 'find_in_page',
                children: [
                    {
                        id: 'stays-page',
                        title: 'Estadias',
                        type: 'item',
                        icon: 'hotel',
                        url: '/auth/reports/stays',
                    },
                    {
                        id: 'occurrence-page',
                        title: 'Ocorrências',
                        type: 'item',
                        icon: 'priority_high',
                        url: '/auth/reports/occurrence',
                    },
                ],
            },
            {
                id: 'logout-page',
                title: 'Sair',
                translate: 'NAV.LOGOUT.TITLE',
                type: 'item',
                icon: 'directions_run',
                url: '/not-auth/logout',
            },
        ],
    },*/
];
