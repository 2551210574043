import { Action } from '@ngrx/store';
import { ArchiveInterface } from 'app/shared/interfaces/archive.interface';

export enum ActionTypes {
    SET = '[archive] ADD',
    CLEAR = '[archive] CLEANNING',
}

export const AddArchiveData = (archiveInterface: ArchiveInterface) => {
    return { type: ActionTypes.SET, payload: archiveInterface } as Action;
};

export const ClearArchiveData = () => {
    return { type: ActionTypes.CLEAR, payload: null } as Action;
};
