import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LoadingService } from 'app/shared/components/several-components/loading/loading.service';
import { Operation } from 'app/shared/enums/operation';
import { Archive } from 'app/shared/models/archive.model';
import { Request } from 'app/shared/models/request.model';
import { ImportService } from 'app/shared/services/import.service';
import { SocketService } from 'app/shared/services/socketio.service';
import { SwalService } from 'app/shared/services/swal.service';
import { UtilsService } from 'app/shared/services/utils.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})

export class FormComponent implements OnInit {

  ELEMENT_DATA: Request[] = [];

  labelButton = 'REALIZAR UPLOAD';
  iconName = 'vertical_align_bottom';
  ocurrencesValidation = '';
  disabledSave;
  Files;
  dataSource;
  dataTable;

  table = false;
  loading = false;
  title: string;
  form: FormGroup;
  operation: Operation;
  fileData: Observable<Archive>;

  displayedColumns =
    [
      'ocurrences', 'NMCENTRO', 'NRPEDIDO', 'NRITEM', 'NRREMESS', 'CDPRODUT',
      'DSPRODUT', 'CDONU', 'DSEMBALA', 'TPPEDIDO', 'TPFRETE',
      'CDBLOQUE', 'DTREQUER', 'QTVOLUME', 'CDGERENC', 'CDCOORDE',
      'NMREPRES', 'RSCLIFAT', 'NMCLIENT', 'NMCIDENT', 'NMUFENTR',
      'CDROTA', 'DSROTEI1', 'DSROTEI2',
    ];

  constructor(
    private _utilsService: UtilsService,
    private _swalService: SwalService,
    private _importService: ImportService,
    private _loadingService: LoadingService,
    private _router: Router,
    private snackBar: MatSnackBar,
    private _socket: SocketService,
    private _store: Store<{ archive: Archive }>,
  ) {
    this.fileData = this._store.pipe(select('archive'));
  }

  ngOnInit(): void {
    this.setOperation();
    this.createForm();

    this.fileData.subscribe(data => {
      if (data.hasErr && this._socket.showError) {
        this.loading = false;
        this._socket.showError = false;
        this._swalService.error('Ops', data.error.message);
      } else if (!data.hasErr && this._socket.showTable) {
        this.dataTable = data.data;
        if (this.dataTable.length > 0) {
          this.createTable(this.dataTable);
        }
      }
    });
    if (this._socket.validationLoading) {
      this.loading = true;
      this.table = false;
    }
  }

  createForm(): void {
    this.form = new FormGroup({
      file: new FormControl(null, Validators.required),
    });
  }

  setOperation(): void {
    this.operation = Operation.NEW;
    this.title = 'Importar Pedidos';
  }

  changeListener(event): void {
    this.Files = event.srcElement.files[0];
    event.srcElement.value = null;
  }

  createTable(dataTable): void {
    this.ELEMENT_DATA = [];
    this.disabledSave = true;
    let ocurrencesRow = false;
    this.ocurrencesValidation = '';
    dataTable.forEach((row, index) => {
      const indexNMCENTRO = row.filter(item => { if (item.hasOwnProperty('NMCENTRO')) { return item; } });
      const indexNRPEDIDO = row.filter(item => { if (item.hasOwnProperty('NRPEDIDO')) { return item; } });
      const indexNRITEM = row.filter(item => { if (item.hasOwnProperty('NRITEM')) { return item; } });
      const indexNRREMESS = row.filter(item => { if (item.hasOwnProperty('NRREMESS')) { return item; } });
      const indexCDPRODUT = row.filter(item => { if (item.hasOwnProperty('CDPRODUT')) { return item; } });
      const indexDSPRODUT = row.filter(item => { if (item.hasOwnProperty('DSPRODUT')) { return item; } });
      const indexCDONU = row.filter(item => { if (item.hasOwnProperty('CDONU')) { return item; } });
      const indexDSEMBALA = row.filter(item => { if (item.hasOwnProperty('DSEMBALA')) { return item; } });
      const indexTPPEDIDO = row.filter(item => { if (item.hasOwnProperty('TPPEDIDO')) { return item; } });
      const indexTPFRETE = row.filter(item => { if (item.hasOwnProperty('TPFRETE')) { return item; } });
      const indexCDBLOQUE = row.filter(item => { if (item.hasOwnProperty('CDBLOQUE')) { return item; } });
      const indexDTREQUER = row.filter(item => { if (item.hasOwnProperty('DTREQUER')) { return item; } });
      const indexQTVOLUME = row.filter(item => { if (item.hasOwnProperty('QTVOLUME')) { return item; } });
      const indexCDGERENC = row.filter(item => { if (item.hasOwnProperty('CDGERENC')) { return item; } });
      const indexCDCOORDE = row.filter(item => { if (item.hasOwnProperty('CDCOORDE')) { return item; } });
      const indexNMREPRES = row.filter(item => { if (item.hasOwnProperty('NMREPRES')) { return item; } });
      const indexRSCLIFAT = row.filter(item => { if (item.hasOwnProperty('RSCLIFAT')) { return item; } });
      const indexNMCLIENT = row.filter(item => { if (item.hasOwnProperty('NMCLIENT')) { return item; } });
      const indexNMCIDENT = row.filter(item => { if (item.hasOwnProperty('NMCIDENT')) { return item; } });
      const indexNMUFENTR = row.filter(item => { if (item.hasOwnProperty('NMUFENTR')) { return item; } });
      const indexCDROTA = row.filter(item => { if (item.hasOwnProperty('CDROTA')) { return item; } });
      const indexDSROTEI1 = row.filter(item => { if (item.hasOwnProperty('DSROTEI1')) { return item; } });
      const indexDSROTEI2 = row.filter(item => { if (item.hasOwnProperty('DSROTEI2')) { return item; } });

      ocurrencesRow = false;

      row.forEach(column => {
        if (column['occurrences']) {
          if (column.occurrences.length >= 1) {
            ocurrencesRow = true;
          }
        }
        if (!column['idFactory']) {
          column.occurrences.forEach(element => {
            this.ocurrencesValidation = element;
          });
        }
      });

      this.ELEMENT_DATA.push({
        register: ocurrencesRow,
        NMCENTRO: row[row.indexOf(indexNMCENTRO[0])].NMCENTRO, ocurrencesNMCENTRO: row[row.indexOf(indexNMCENTRO[0])].occurrences,
        NRPEDIDO: row[row.indexOf(indexNRPEDIDO[0])].NRPEDIDO, ocurrencesNRPEDIDO: row[row.indexOf(indexNRPEDIDO[0])].occurrences,
        NRITEM: row[row.indexOf(indexNRITEM[0])].NRITEM, ocurrencesNRITEM: row[row.indexOf(indexNRITEM[0])].occurrences,
        NRREMESS: row[row.indexOf(indexNRREMESS[0])].NRREMESS, ocurrencesNRREMESS: row[row.indexOf(indexNRREMESS[0])].occurrences,
        CDPRODUT: row[row.indexOf(indexCDPRODUT[0])].CDPRODUT, ocurrencesCDPRODUT: row[row.indexOf(indexCDPRODUT[0])].occurrences,
        DSPRODUT: row[row.indexOf(indexDSPRODUT[0])].DSPRODUT, ocurrencesDSPRODUT: row[row.indexOf(indexDSPRODUT[0])].occurrences,
        CDONU: row[row.indexOf(indexCDONU[0])].CDONU, ocurrencesCDONU: row[row.indexOf(indexCDONU[0])].occurrences,
        DSEMBALA: row[row.indexOf(indexDSEMBALA[0])].DSEMBALA, ocurrencesDSEMBALA: row[row.indexOf(indexDSEMBALA[0])].occurrences,
        TPPEDIDO: row[row.indexOf(indexTPPEDIDO[0])].TPPEDIDO, ocurrencesTPPEDIDO: row[row.indexOf(indexTPPEDIDO[0])].occurrences,
        TPFRETE: row[row.indexOf(indexTPFRETE[0])].TPFRETE, ocurrencesTPFRETE: row[row.indexOf(indexTPFRETE[0])].occurrences,
        CDBLOQUE: row[row.indexOf(indexCDBLOQUE[0])].CDBLOQUE, ocurrencesCDBLOQUE: row[row.indexOf(indexCDBLOQUE[0])].occurrences,
        DTREQUER: moment(indexDTREQUER[0].DTREQUER).format('DD-MM-YYYY'), ocurrencesDTREQUER: row[row.indexOf(indexDTREQUER[0])].occurrences,
        QTVOLUME: row[row.indexOf(indexQTVOLUME[0])].QTVOLUME, ocurrencesQTVOLUME: row[row.indexOf(indexQTVOLUME[0])].occurrences,
        CDGERENC: row[row.indexOf(indexCDGERENC[0])].CDGERENC, ocurrencesCDGERENC: row[row.indexOf(indexCDGERENC[0])].occurrences,
        CDCOORDE: row[row.indexOf(indexCDCOORDE[0])].CDCOORDE, ocurrencesCDCOORDE: row[row.indexOf(indexCDCOORDE[0])].occurrences,
        NMREPRES: row[row.indexOf(indexNMREPRES[0])].NMREPRES, ocurrencesNMREPRES: row[row.indexOf(indexNMREPRES[0])].occurrences,
        RSCLIFAT: row[row.indexOf(indexRSCLIFAT[0])].RSCLIFAT, ocurrencesRSCLIFAT: row[row.indexOf(indexRSCLIFAT[0])].occurrences,
        NMCLIENT: row[row.indexOf(indexNMCLIENT[0])].NMCLIENT, ocurrencesNMCLIENT: row[row.indexOf(indexNMCLIENT[0])].occurrences,
        NMCIDENT: row[row.indexOf(indexNMCIDENT[0])].NMCIDENT, ocurrencesNMCIDENT: row[row.indexOf(indexNMCIDENT[0])].occurrences,
        NMUFENTR: row[row.indexOf(indexNMUFENTR[0])].NMUFENTR, ocurrencesNMUFENTR: row[row.indexOf(indexNMUFENTR[0])].occurrences,
        CDROTA: row[row.indexOf(indexCDROTA[0])].CDROTA, ocurrencesCDROTA: row[row.indexOf(indexCDROTA[0])].occurrences,
        DSROTEI1: row[row.indexOf(indexDSROTEI1[0])].DSROTEI1, ocurrencesDSROTEI1: row[row.indexOf(indexDSROTEI1[0])].occurrences,
        DSROTEI2: row[row.indexOf(indexDSROTEI2[0])].DSROTEI2, ocurrencesDSROTEI2: row[row.indexOf(indexDSROTEI2[0])].occurrences,
      });
    });

    if (this.ocurrencesValidation === '') {
      this.disabledSave = false;
    }
    this.dataSource = this.ELEMENT_DATA;
    this.loading = false;
    this.table = true;
  }

  open(): void {
    const config = new MatSnackBarConfig();
    const message = 'Seu pedido já foi importado, Clique aqui para ser redirecionado para a página de importação.';
    const actionButtonLabel = 'Ir para';
    const action = true;
    const setAutoHide = true;
    const autoHide = 5000;

    config.duration = setAutoHide ? autoHide : 0;
    this.snackBar.open(message, action ? actionButtonLabel : undefined, config);

    this.snackBar._openedSnackBarRef.onAction().subscribe(res => {
      this._router.navigate(['auth/request/import/new']);
    });
  }

  onUpload(): void {
    this.table = false;
    this.dataTable = '';

    if (this._utilsService.formIsValid(this.form) && (this.Files.size <= 20971520)) {
      this._loadingService.show();
      const formData = new FormData();
      formData.set('file', this.Files);
      this._importService.upload(formData).subscribe(data => {
        this._loadingService.hide();
        this._swalService.success(data['data']);
        this._socket.validationLoading = true;
        this._socket.showTable = true;
        this._socket.showError = true;
        this.loading = true;
        this.form.controls.file.reset();
      }, (error) => {
        this._loadingService.hide();
        this._swalService.error('Ops', error.error.message);
      });
    } else if (this.Files.size > 20971520) {
      this._swalService.error('Ops', 'Tamanho do arquivo é maior que 20MB');
    }
  }

  onSave(): void {
    this._loadingService.show();
    this._importService.create(this.dataTable).subscribe(data => {
      this._socket.showTable = false;
      this.table = false;
      this.loading = false;
      this._loadingService.hide();
      this._swalService.success('Operação realizada com sucesso.');
    }, (error) => {
      this._loadingService.hide();
      this._swalService.error('Ops', error.error.message);
    });
  }
}
