import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'app',
        loadChildren: () => import('./modules/download/download.module').then(m => m.DownloadModule),
      },
      {
        path: 'schedule-fob',
        loadChildren: () => import('./modules/schedule-fob/schedule-fob.module').then(m => m.ScheduleFobModule),
      },
      {
        path: 'not-auth',
        loadChildren: () => import('./modules/not-auth/not-auth.module').then(m => m.NotAuthModule),
      },
      {
        path: 'auth',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: '**',
        redirectTo: 'auth',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [RoleGuard],
  exports: [RouterModule],
})
export class AppRoutingModule { }
