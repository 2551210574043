export class Chat {
    id?: number;
    name?: string;
    shippingCompany?: string;
    plaques?: string;
    date?: string;
    unread?: number;
    dialog?: {
            id?: number,
            email?: string,
            message?: string,
            time?: string,
            photo?: string,
            justify?: string,
            occurrenceType?: string,
            lifespanOccurrence?: string,
        }[];
}
