import { ActionInterface } from 'app/shared/interfaces/action.interface';
import { Archive } from 'app/shared/models/archive.model';
import { ActionTypes } from '../archive/actions';

export const initialState: Archive = { hasErr: false, data: [] };

export function archiveReducer(state = initialState, action: ActionInterface): Archive {
    switch (action.type) {
        case ActionTypes.SET: {
            state = action.payload;
            return state;
        }

        case ActionTypes.CLEAR: {
            state = initialState;
            return state;
        }
        default:
            return state;
    }
}
