import { Action } from '@ngrx/store';
import { ChatInterface } from 'app/shared/interfaces/chat.interface';

export enum ActionTypes {
    SET = '[message] ADD',
    CLEAR = '[message] CLEANNING',
}

export const SetMessageData = (authInterface: ChatInterface) => {
    return { type: ActionTypes.SET, payload: authInterface } as Action;
};

export const ClearMessageData = () => {
    return { type: ActionTypes.CLEAR, payload: null } as Action;
};
