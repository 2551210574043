export const locale = {
    lang: 'tr',
    data: {
        NAV: {
            APPLICATIONS: 'Applications',
            ADMINISTRATIVE: {
                TITLE: 'Administrative',
                SHIPPING: {
                    TITLE: 'Shipping Company',
                    BADGE: '25',
                },
            },
            CONSULT: {
                TITLE: 'Consult',
            },
            DASHBOARD: {
                TITLE: 'Dashboard',
            },
            LOGISTIC: {
                TITLE: 'Logistic',
            },
            OPERATION: {
                TITLE: 'Operation',
            },
            REQUEST: {
                TITLE: 'Request',
            },
            CONCIERGE: {
                TITLE: 'Concierge',
            },
            REPORTS: {
                TITLE: 'Reports',
            },
            LOGOUT: {
                TITLE: 'Logout',
            },
        },
    },
};
