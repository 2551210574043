import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { merge, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MenuService } from 'app/shared/services/menu.service';
import { Auth } from 'app/shared/models/auth.model';
import { Store, select } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { SwalService } from 'app/shared/services/swal.service';
import { UtilsService } from 'app/shared/services/utils.service';
import { Chat } from 'app/shared/models/chat.model';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    authData: Observable<Auth>;
    token: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    @ViewChild('forceDiv', { static: false }) myDiv: ElementRef<HTMLElement>;


    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _swalService: SwalService,
        private _menuService: MenuService,
        private _store: Store<{ auth: Auth }>,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _utilsService: UtilsService,
        private _storeMessage: Store<{ message: Chat }>
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        
        this.authData = this._store.pipe(select('auth'));
    
        this.authData.subscribe(data => {
            this.token = data.token;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        this._utilsService.decrementBadgeSubject.subscribe(data => {
            this.badgeMessages();
        });

        const messageData = this._storeMessage.pipe(select('message'));

        messageData.subscribe(data => {
            if (data.hasOwnProperty('id')) {
                this.badgeMessages();
            }
        });
        

        if (this.token) {
            this._menuService.loadByUser().pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
                this.navigation = this._utilsService.formatData(response.data.menus);
                this.badgeMessages();
                this._changeDetectorRef.markForCheck();
            }, error => {
                this._swalService.error('Ops', error.error.message);
            });
        } else {
/*             this._router.navigate(['not-auth/'], {
                relativeTo: this._activatedRoute,
            }); */
        }

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });
    }
    forceDiv(): void{}

    badgeMessages(): void {
        let contador = 0;
        this._menuService.loadMessagesBadge().pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
            contador = response.data;

            if (this.navigation) {
                this.navigation[0].children.forEach((i) => {
                    if (i.title === 'Consultar') {
                        i.children.forEach((m) => {
                            if (m.title === 'Mensagens') {
                                m.badge = contador;
                                const el: HTMLElement = this.myDiv.nativeElement;
                                el.click();
                            }
                        });
                    }

                    if (i.title === 'Logística') {
                        i.children.forEach((m) => {
                            if (m.title === 'Mensagens') {
                                m.badge = contador;
                                const el: HTMLElement = this.myDiv.nativeElement;
                                el.click();
                            }
                        });
                    }

                });
            }

        }, error => {
            console.log(error);
        });
    }
}
