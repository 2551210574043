import { Chat } from 'app/shared/models/chat.model';
import { ActionTypes } from '../message/actions';
import { ActionInterface } from 'app/shared/interfaces/action.interface';

export const initialState = new Chat();

export function messageReducer(state = initialState, action: ActionInterface): Chat {
    switch (action.type) {
        case ActionTypes.SET: {
                state = action.payload;
                return state;
            }

        case ActionTypes.CLEAR: {
                state = initialState;
                return state;
            }

        default:
            return state;
    }
}
