import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormComponent } from 'app/modules/auth/request/import/form/form.component';
import { Archive } from 'app/shared/models/archive.model';
import { Chat } from 'app/shared/models/chat.model';
import { Auth } from 'app/shared/models/auth.model';
import { AddArchiveData } from 'app/store/archive/actions';
import { SetMessageData } from 'app/store/message/actions';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client/dist/socket.io';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  authData: Observable<Auth>;
  fileData: Observable<Archive>;
  messageData: Observable<Chat>;
  validationLoading: boolean;
  showTable = true;
  showError = true;
  msgError;
  socket;

  factory:number;

  constructor(
    private _store: Store<{ auth: Auth }>,
    private _storeFile: Store<{ archive: Archive }>,
    private _storeMessage: Store<{ message: Chat }>,
    private _formComponent: FormComponent
  ) {
    this.authData = this._store.pipe(select('auth'));
    this.authData.subscribe(data => {
      if (data.hasOwnProperty('user')) {
        this.factory = parseInt(data.factory.idFactory);
        this.setupSocketConnection(data.user.idUser);
      }
    });
  }

  setupSocketConnection(user): void {
    this.socket = io.connect(environment.API, { query: { uid: user } });
    this.listeningProcessedFile();
    this.listeningMessageFromApp();
  }

  listeningProcessedFile(): void {
    this.socket.on('processFinished', data => {
      if (data.hasErr) {
        data.error = { hasErr: data.hasErr, message: data.data };
        this.validationLoading = false;
        this._storeFile.dispatch(AddArchiveData(data));
      } else {
        this.validationLoading = false;
        this._storeFile.dispatch(AddArchiveData(data));
        this._formComponent.open();
      }
    });
  }

  listeningMessageFromApp(): void {
    this.socket.on('messageFromApp', data => {
      if (data.hasErr) {
        this.msgError = data[0];
      } else {

        if(data.hasOwnProperty('factory') && parseInt(data.factory) == this.factory){
          this._storeMessage.dispatch(SetMessageData(data));
        }
      }
    });
  }
}
