import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlSegment, Route } from '@angular/router';
import { HttpService } from 'app/shared/services/http.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanLoad {

  API_BASE_URL: string = environment.API;

  constructor(
    private _router: Router,
    private _httpService: HttpService,
  ) {
    this.API_BASE_URL = environment.API;
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return this.verifyPermissions(segments);
  }

  verifyPermissions(segments): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      
      if (segments.length >= 2) {
        const obj = this._formatData(segments);

        this._verifyPermission(obj).then(res =>
          resolve(true)
        ).catch(error =>
          this._router.navigate(['/auth']).then(() => resolve(false))
        );
      } else {
        resolve(true);
      }
    });
  }

  private _verifyPermission(obj): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const item = this._verifyPermissionRoute(obj).subscribe(
          data => {
            resolve(data);
            item.unsubscribe();
          }, error => {
            reject(error);
            item.unsubscribe();
          },
      );
    });
  }

  private _verifyPermissionRoute(obj?): Observable<any> {
    const url = `${this.API_BASE_URL}/auth/verify-permission`;

    return this._httpService.post(url, obj);
  }

  private _formatData(segment): object {
    let urlMenu = '/auth';
    let urlAction = `/${segment[segment.length - 1].path}`;

    for (let i = 0; i <= 1; i++) {
      urlMenu =  `${urlMenu}/${segment[i].path}`;

      if (`/${segment[i].path}` === urlAction) {
        urlAction = '/index';
      }
    }

    const obj = { urlMenu, urlAction};

    return obj;
  }
}
